<template>
  <nav role="navigation">
    <div id="menuToggle">
      <input type="checkbox" />
      <span></span>
      <span></span>
      <span></span>
      <ul id="menu" >
        <router-link
          v-for="menuItem in menuItems"
          :key="menuItem"
          class="nav-link"
          :to="{ name: menuItem.object, params: { id: menuItem.object_id, url: menuItem.url } }"
        >
          {{ menuItem.title }}
        </router-link>
      </ul>
    </div>
  </nav>
</template>
<script>
import axios from "axios";
export default {
  name: "navigation",
  emits:['hideMenu'],

  data() {
    return {
      menuItems: null,
      endpoint: "https://kols.futurehost.net.pl/wp-json/myroutes/sidemenu",
    };
  },
  created() {
    this.getMenuitems();
  },
   methods: {

    getMenuitems() {
      axios
        .get(this.endpoint)
        .then((response) => {
          this.menuItems = response.data;
         // console.log(response.data);
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
  },
};
</script>
<style scoped>
.nav-link {
  display: block;
  text-align: right;
  color: #fff;
  margin: 8px 0;
}
nav {
  position: absolute;
  top: 30px;
  right: 20px;
  z-index: 2;
}
#menuToggle {
  display: block;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #fff;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 23px;
  height: 1px;
  margin-bottom: 7px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 3px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-1px, 1px);
  background: #fff;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -3px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 270px;
  margin: 0px 0 0 -300px;
  top: -30px;

  padding: 50px;
  padding-top: 125px;
  min-height: 100vh;
  background: #1b0260;
  list-style-type: none;
  box-shadow: 0 0 10px #000;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul {
  transform: none;
}
</style>
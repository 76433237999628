<template>
  <!-- <h1>{{posts.title.rendered}}</h1> -->
  <!-- <div v-html="posts.content.rendered"></div> -->
  <div class="video__content">
  <iframe
    width="100%"
    v-bind:src="serviceVideoVideoId"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
  </div>
</template>

<script>
export default {
  name: "serviceVideo",
  props: {
    serviceVideoVideoId: String,
  },
  data() {
    return {
    };
  },
  created() {
  },
  async mounted() {
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.video__content{
  width: calc(100% - 20px);
  margin: 0 10px;
  position: relative;
}
iframe{
  height: 54vw;
  max-height: 500px;
}
</style>

<template>
  <div class="container">
    <div class="header">
      <div class="row">
        <a class="header__arrow" v-on:click="previousPath">
          <img src="@/assets/images/arrowLeft.png" alt="strona główna" />
        </a>
      </div>
      <div class="row">
        <h1>{{ title }}</h1>
      </div>
    </div>
        <div class="content" v-if="content" v-html="content"></div>
    <footerComponent />
  </div>
</template>
<script>
// @ is an alias to /src
import footerComponent from "@/components/footerComponent.vue";
import axios from "axios";
export default {
  name: "page",
  components: {
    footerComponent,
  },
  data() {
    return {
      posts: null,
      title: null,
      content: null,
      featuredImage: null,
      endpoint:
        "https://kols.futurehost.net.pl/wp-json/wp/v2/pages/" //+ this.$route.params.id+"?_embed",
    };
  },
  async mounted() {
    this.getPosts(this.$route.params.id);
  },
  watch:{
    $route (to, from){
        //this.show = false;
        //console.log("chagnge",this.$route.params.id);
        this.getPosts(this.$route.params.id);
    }
    },
  methods: {
    previousPath() {
     return window.history.back();
    },
    async getPosts(pid) {
     await axios
        .get(this.endpoint+pid+"?_embed")
        .then((response) => {
        //  console.log(response.data.title.rendered);
        //  this.featuredImage= response.data._embedded['wp:featuredmedia']['0'].source_url;
          this.posts = response.data;
          this.title = response.data.title.rendered;
          this.content = response.data.content.rendered;

        })
        .catch((error) => {
          console.log("error ! -> ", error);
        });
    },
  },
};
</script>
<style scoped>
.header {
  background: #1b0260;
  padding: 10px 0;
}
.row {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  padding: 0px 10px;
}
h1 {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
}
.container {
  min-height: calc(100vh - 100px);
}
.content{
  margin: 10px;
  min-height: calc(100vh - 160px);
}
:deep(.content) p{
  text-align: left;
}
:deep(.content) ul{
  text-align: left;
}
:deep(.content) h2{
  text-align: left;
}
:deep(.content) h3{
  text-align: left;
}
:deep(.content) .wp-block-embed{
  margin: 10px;
  }
  :deep(.content) .wp-block-embed__wrapper{
    margin: 0;
    text-align: left;
  }
:deep(.content) iframe{
  max-width: 98%;
}
:deep(.content) p {
  text-align: left;
}
:deep(.content) ul {
  text-align: left;
  padding-left: 20px;
}
:deep(.content) ul li {
  list-style-type: none;
  position: relative;
  margin: 10px 0;
}
:deep(.content) ul li:after {
  position: absolute;
  left: -20px;
  top: 2px;
  content: "";
  width: 15px;
  height: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("~@/assets/images/tick.png");
}
:deep(.content) .alert {
  position: relative;
  background: #e5e5e5;
  border-radius: 10px;
  padding: 10px 10px;
  text-align: left;
  margin-bottom: 20px;
}
:deep(.content) .alert__triangle {
  position: absolute;
  bottom: -3px;
  left: -9px;
  width: 15px;
  height: 15px;
  background-image: url("`~@/assets/images/triangle.png`");
}
:deep(.content) .alert p {
  padding: 0px 0;
  margin: 0;
  line-height: 1.45rem;
}
ol li{
  text-align: left;
}
.wp-block-media-text{
  width: 100%;
  display: flex;
}
:deep(.content) .wp-block-media-text__media{
display: flex;
justify-content: center;
margin: 0 5px;
}
:deep(.content) .wp-block-media-text__media img{
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}
:deep(.content) h2{
  color: #ff7a07;
  text-align: left;
}
:deep(.content) h3{
  color: #1b0260;
  text-align: left;
}
:deep(.content) .wp-block-image{
margin: 0;
padding: 0;
display: flex;
}
:deep(.content) .wp-block-image .alignleft{
  display: flex;
  align-items: start;
  width: 100%;
  margin: 0;
}
:deep(.content) .wp-block-image img{
  max-width: 100%;
  height: auto;
}
:deep(.content) .wp-block-image.rounded img{
border-radius: 10px;
}
:deep(.content) ol li {
  text-align:left;
}
:deep(.content) ol > li {
  padding: 10px;
}
</style>
<template>
  <div class="checknews">
    <router-link class="checknews__link" to="/news">
      Sprawdź
      <img v-bind:src="logoFooter" class="checknews__logo" alt="Logo" /> newsy
      <img src="@/assets/images/arrowRight.png"
    /></router-link>
  </div>
</template>
<script>
export default {
  name: "checkNewsComponent",
  props: {
    logoFooter: String,
  },
};
</script>
<style scoped>
.checknews {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  padding: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.checknews__link {
  font-size: 1rem;
  font-weight: 400;
  color: #1b0260;
  text-decoration: none;
  padding: 0 10px;
}
.checknews__logo {
  height: 16px;
  width: auto;
}
</style>
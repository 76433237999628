<template>
  <div class="wrapper">
   <!-- <pageLoader v-bind:isloaded="isloaded"/> -->
    <navigation />
    <prompt1/>
    <!-- :key="$route.path" -->
    <router-view v-slot="{ Component, route }">
      <transition 
      :enter-active-class="route.meta.enterClass" 
      :leave-active-class="route.meta.leaveClass" 
      >
      <component :is="Component" class="component-wrapper"></component>

      </transition>
    </router-view>
    <div>
    </div>
    <div
      class="bg"
      v-if="background"
      v-bind:style="{ 'background-image': 'url(' + background + ')' }"
    ></div>
  </div>
</template>
<script>
// @ is an alias to /src
import navigation from "@/components/navigation.vue";
import pageLoader from "@/components/pageLoader.vue";
import prompt1 from "@/components/prompt1.vue";


import axios from "axios";
export default {
  name: "Home",
  components: {
    navigation,
    pageLoader,
    prompt1
  },
  data() {
    return {
      background: null,
      logo: null,
      prevRoute: null,
    };
  },
  created() {
    this.getBg();
    document.title="KOLS"
  },
  mounted() {
    //check if service worker is available in browser

if("serviceWorker" in navigator) {
   navigator.serviceWorker.register("/service-worker.js").then(reg => {
      console.log("Registration succesful, scope: " + reg.scope);
   })
   .catch(err => {
      console.log(err);
   })
}
  },

  methods: {
    getBg() {
      const response = axios
        .get("https://kols.futurehost.net.pl/wp-json/kols/v2/settings/body_background")
        .then((response) => {
          this.background = response.data;
        })
        .catch((error) => {
          console.log("e", error);
        });
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap");
@import url("~@/assets/styles/animate.css");

#app {
  font-family: "Arimo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  width: 100%;
  position: relative;
  min-height: 100vh;
  max-width: 1024px;
  margin: 0 auto;
}
.p-absolute{
  position: absolute!important;
}
.component-wrapper{
  /* position: absolute!important; */
  top:0px;
  width: 100%;
  z-index: 1;
}
body {
  margin: 0;
  position: relative;
  min-height: 100vh;
}
body.overflowhidden {
  overflow: hidden;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.bg {
  position: fixed;
  top: 30vh;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  opacity: 0.2;
}
/* This changes all the animations globally */
:root {
  --animate-duration: 800ms;
  --animate-delay: 4s;
}
</style>

<template>
  <div class="news">
    <router-link
      v-for="post in posts"
      v-bind:key="post"
      v-bind:class="'news__item'"
      :to="{ name: 'singleNews', params: { id: post.id } }"
    >
      <article class="news__single">
        <div class="news__colLeft">
          <img
            class="news__image"
            v-bind:src="post._embedded['wp:featuredmedia']['0'].source_url"
            alt=""
          />
        </div>
        <div class="news__colRight">
          <span class="news__date" v-html="modifyDate(post.date)"></span>
          <h2 class="news__h2">{{ post.title.rendered }}</h2>
        </div>
      </article>
    </router-link>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "newsList",

  data() {
    return {
      posts: null,
      endpoint: "https://kols.futurehost.net.pl/wp-json/wp/v2/posts?_embed",
    };
  },
  created() {
    this.getAllPosts();
  },
  methods: {
    getAllPosts() {
      axios
        .get(this.endpoint)
        .then((response) => {
          this.posts = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log("error ! -> ", error);
        });
    },
    modifyDate(date) {
      date = date.split("T");
      date = new Date(date[0]);
      const months = [
        "stycznia",
        "lutego",
        "marca",
        "kwietnia",
        "maja",
        "czerwca",
        "lipca",
        "sierpnia",
        "września",
        "października",
        "listopada",
        "grudnia",
      ];
      const currentMonth = months[date.getMonth()];
      return `${date.getDay()} ${currentMonth} ${date.getYear() + 1900}`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.news{
  padding: 10px;
}
.news__single{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 20px 0;
}
.news__item{
  text-decoration: none;
}
.news__colLeft{
  width: 50px;
  height: 50px;
  position: relative;
}
.news__image{
  width: 100%;
  object-fit: cover;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  
}
.news__colRight{
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;  
padding: 0 10px;
}
.news__h2{
  font-size: 1rem;
  color: #1B0260;
  text-decoration: none;
  text-align: left;
  padding: 0;
  margin: 5px 0;
}
.news__date{
  font-size: .8rem;
  color: #1B0260;
}
</style>

import { createRouter, createWebHistory, useLink } from 'vue-router'
import Home from '../views/Home.vue'
//import servicesList from '../components/servicesList.vue'
import service from '../views/service.vue'
import callrequest from '../views/callrequest.vue'
import sendForm from '../views/sendForm.vue'
import news from '../views/news.vue'
import singleNews from '../views/singleNews.vue'
import page from '../views/page.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    //component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),

    meta:{
      enterClass:"animate__animated animate__fadeInLeft p-absolute animate__faster",
      leaveClass:"animate__animated animate__fadeOutRight p-absolute animate__faster"
    }
    // beforeEnter(to,from,next){
    //   document.querySelector('#menuToggle input').checked=false;
    //   next()
    // }
  },

  // {
  //   path: '/servicesList',
  //   name: 'servicesList',
  //   component: servicesList,
  //   meta:{
  //     enterClass:"animate__animated animate__flipInX",
  //     leaveClass:"animate__animated animate__flipInX"
  //   }
  // },
  {
    path: '/service/:id',
    name: 'service',
  //  component: () => import(/* webpackChunkName: "about" */ '../views/service.vue'),
    component: service,
    
    meta:{
      enterClass:"animate__animated animate__fadeInRight p-absolute animate__animate__faster",
      leaveClass:"animate__animated animate__fadeOutLeft p-absolute animate__animate__faster"
    },
    // beforeEnter(to,from,next){
    //   document.querySelector('#menuToggle input').checked=false;
    //   next()
    // }
  },
  {
    path: '/callrequest',
    name: 'callrequest',
    //component: () => import(/* webpackChunkName: "about" */ '../views/callrequest.vue'),
    component: callrequest,
    meta:{
      enterClass:"animate__animated animate__fadeIn p-absolute animate__faster",
      leaveClass:"animate__animated animate__fadeOut p-absolute animate__faster"
    },
    // beforeEnter(to,from,next){
    //   document.querySelector('#menuToggle input').checked=false;
    //   next()
    // }
  },
  {
    path: '/sendForm',
    name: 'sendForm',
    //component: () => import(/* webpackChunkName: "about" */ '../views/sendForm.vue'),
    component: sendForm,
    meta:{
      enterClass:"animate__animated animate__fadeIn p-absolute animate__faster",
      leaveClass:"animate__animated animate__fadeOut p-absolute animate__faster"
    },
    // beforeEnter(to,from,next){
    //   document.querySelector('#menuToggle input').checked=false;
    //   next()
    // }
  },
  {
    path: '/news',
    name: 'news',
   // component: () => import(/* webpackChunkName: "about" */ '../views/news.vue'),
   component: news,
    meta:{
      enterClass:"animate__animated animate__fadeInRight p-absolute animate__faster",
      leaveClass:"animate__animated animate__fadeOutLeft p-absolute animate__faster"
    },
    // beforeEnter(to,from,next){
    //   document.querySelector('#menuToggle input').checked=false;
    //   next()
    // }
  },
  {
    path: '/news/:id',
    name: 'singleNews',
    //component: () => import(/* webpackChunkName: "about" */ '../views/singleNews.vue'),
  component: singleNews,
    meta:{
      enterClass:"animate__animated animate__fadeIn p-absolute animate__faster",
      leaveClass:"animate__animated animate__fadeOut p-absolute animate__faster"
    },
 
    // beforeEnter(to,from,next){
    //   document.querySelector('#menuToggle input').checked=false;
    //   next()
    // }
  },
  {
    path: '/news/:id',
    name: 'post',
    //component: () => import(/* webpackChunkName: "about" */ '../views/singleNews.vue'),
  component: singleNews,
    meta:{
      enterClass:"animate__animated animate__fadeIn p-absolute animate__faster",
      leaveClass:"animate__animated animate__fadeOut p-absolute animate__faster"
    },
    // beforeEnter(to,from,next){
    //   document.querySelector('#menuToggle input').checked=false;
    //   next()
    // }
  },
  {
    path: '/page/:id',
    name: 'page',
    //component: () => import(/* webpackChunkName: "about" */ '../views/page.vue'),
   component: page,
    meta:{
      enterClass:"animate__animated animate__fadeInRight p-absolute animate__faster",
      leaveClass:"animate__animated animate__fadeOutLeft p-absolute animate__faster"
    },
    // beforeEnter(to,from,next){
    //   document.querySelector('#menuToggle input').checked=false;
    //   next()
    // }
  },
  {
    path: '/',
    name: 'custom',
    beforeEnter(to, from, next){
     // console.log(to, from,next)
    location.href=to.params.url
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  document.querySelector('#menuToggle input').checked=false;
  next()})
export default router

<template>
<div class="footer">
    
<router-link to="/" class="footer__home">
    <img src="@/assets/images/home.png" alt="Powrót do strony głównej">
    <span>Home</span>
</router-link>

    
    </div> 
</template>
<script>

export default ({
name: "footerComponent",

})
</script>
<style scoped>
.footer{
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
  width: 100%;
  height: 50px;
  z-index: 2;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
 background: rgb(255,255,255);
background: linear-gradient(0deg, rgba(255,255,255,0.014443277310924318) 0%, rgba(255,255,255,0.46262254901960786) 0%, rgba(255,255,255,0.020045518207282953) 100%);
}
.footer__home{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    color: #1B0260;
}
.footer__home span{
    width: 100%;
    text-align: center;
font-size: .65rem;
padding: 3px 0;

}
</style>
<template>
  <div v-if="prepared" class="container">
    <div class="header">
      <div class="row">
        <router-link class="header__arrow" to="/">
          <img src="@/assets/images/arrowLeft.png" alt="strona główna" />
        </router-link>
      </div>
      <div class="row">
        <img v-if="icon" v-bind:src="icon" class="header__icon" />
        <h1>{{ title }}</h1>
      </div>
    </div>
    <div class="content">
      <ul v-if="isVideo" class="content__switcher">
        <li>
          <button class="active" v-on:click="changeView" data-to="desc" data-id="desc">
            Informacje
          </button>
        </li>
        <li>
          <button class="ytbutton" v-on:click="changeView" data-to="video" data-id="video"><img src="@/assets/images/youTube_icon.svg" alt="video"> Video</button>
        </li>
      </ul>
      <div
        data-content="desc"
        class="tab active"
        v-bind:class="{ pt1: !isVideo }"
      >
        <serviceDesc
          v-bind:serviceDescContent="content"
          v-bind:serviceDescAlert="alert"
          v-bind:serviceDescPhone="phone"
          v-bind:serviceDescEmail="email"
          v-bind:serviceDescFeatured="featuredImage"
        />
      </div>
      <div data-content="video" class="tab">
        <serviceVideo v-for="videoId in videoIds" :key="videoId" v-bind:serviceVideoVideoId="videoId" />
                  <button class="primary-button" v-on:click="changeView" data-to="desc" data-id="desc">Informacje</button>

      </div>
    </div>
    <footerComponent />
  </div>
</template>
<script>
// @ is an alias to /src
import serviceDesc from "@/components/serviceDesc.vue";
import serviceVideo from "@/components/serviceVideo.vue";
import footerComponent from "@/components/footerComponent.vue";
import axios from "axios";
export default {
  name: "service",
  components: {
    serviceDesc,
    serviceVideo,
    footerComponent,
  },
  data() {
    return {
      prepared:false,
      posts: null,
      icon: null,
      title: null,
      content: null,
      phone: null,
      email: null,
      alert: null,
      isInfoActive: true,
      isVideoActive: false,
      featuredImage: null,
      videoIds: [],
      isVideo: false,
      endpoint:
        "https://kols.futurehost.net.pl/wp-json/wp/v2/uslugi/" +
        this.$route.params.id +
        "?_embed",
      mediaEndpoint: "https://kols.futurehost.net.pl/wp-json/wp/v2/media/",
    };
  },
  async created() {
    this.getPosts();
  },
  methods: {
    changeView(event) {
      const activeEl = event.target;
      const switchTo= activeEl.dataset.to;
      console.log(event.target);
      const switchers = document.querySelectorAll(
        ".content__switcher li button"
      );

      switchers.forEach((switcher) => {
        switcher.classList.remove("active");
      });
      document.querySelector(`[data-id=${switchTo}]`).classList.add('active');
      const tabs = document.querySelectorAll(".tab");
      tabs.forEach((tab) => {
        if (tab.dataset.content == switchTo) {
          tab.classList.add("active");
        } else {
          tab.classList.remove("active");
        }
      });
    },
   async getPosts() {
     await axios
        .get(this.endpoint)
        .then((response) => {
          this.featuredImage = response.data._embedded["wp:featuredmedia"]["0"].source_url;
          this.posts = response.data;
          this.title = response.data.title.rendered.replace("|", "");
          this.content = response.data.content.rendered;
          this.alert = response.data.acf.alert;
          this.phone = response.data.acf.telefon;
          this.email = response.data.acf.email;
          let videoIdsString= response.data.acf.video_id;

         // console.log('videoIds',videoIds);
          if (videoIdsString.length > 1) {

          const videoIdsArray= videoIdsString.split(',');
          const ytUrl="https://www.youtube.com/embed/";
          videoIdsArray.forEach((videoId)=>{
            this.videoIds.push(ytUrl+videoId)
            }
            );
          this.isVideo = true;
          }
          this.getMedia();
        })
        .catch((error) => {
          console.log("error ! -> ", error);
        });
    },
    getMedia() {
     // console.log("ikonka", this.posts.acf.ikonka);
      axios
        .get(this.mediaEndpoint + this.posts.acf.ikonka)
        .then((response) => {
          this.icon = response.data.guid.rendered.replace("http://", 'https://');
          this.prepared=1
        })
        .catch((error) => {
          console.log("media error", error);
        });
    },
  },
};
</script>
<style scoped>
.header {
  background: #1b0260;
  padding: 10px 0;
}
.row {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
}
h1 {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  padding: 0 10px;
}
.content {
  min-height: calc(100vh - 160px);
}
.content__switcher {
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: flex-start;
  padding: 0 10px;
  font-size: 1rem;
  margin: 10px;
}
.content__switcher li {
  width: auto;
  display: flex;
  align-items: center;

}
.content__switcher li button {
  background: none;
  border: none;
  color: #1b0260;
  text-transform: uppercase;
  margin: 0 20px 0 0;
  padding: 5px 10px;
  font-size: .95rem;
    font-weight: 700;
    border-radius: 20px;
    

  /* transition: all 4s ease-in-out; */
}
.content__switcher li button.active {
  color: #1b0260;
  border: 1px solid #ff7a07;
  
}
.header__icon {
  width: auto;
  height: auto;
  max-height: 30px;
}
@keyframes changeTab {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.tab {
  display: none;
  width: 90%;
  margin: 0 auto;
  opacity: 0;
  position: relative;
  z-index: 1;
}
.tab.active {
  animation-name: changeTab;
  animation-duration: 1s;
  display: block;
  animation-fill-mode: forwards;
}
.pt1 {
  padding-top: 1em;
}
.container {
  min-height: calc(100vh - 100px);
}
.primary-button{
  padding: 10px 20px;
  background: #ff7a07;
  color: #fff;
  border: none;
  margin: 10px 0;
}
.ytbutton{
  display: flex;
justify-content: flex-start;
align-items: center;
}
.ytbutton img{
  width: 30px;
  height: auto;
  margin: 0 5px 0 0;

}
</style>
<template>
  <div v-if="!isloaded" class="page__loader"></div>
</template>
<script>
export default {
  name: "pageLoader",
  props:{
      isloaded: Boolean
  },
};
</script>
<style scoped>
.page__loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ddd;
  z-index: 9;
}
</style>
<template>
  <div class="container">
    <div class="header">
      <div class="row">
        <a class="header__arrow" v-on:click="previousPath">
          <img src="@/assets/images/arrowLeft.png" alt="strona główna" />
        </a>
      </div>
      <div class="row">
        <h1>
          <img v-bind:src="logoFooter" class="checknews__logo" alt="Logo" />
          Newsy
        </h1>
      </div>
    </div>
    <div class="container">
      <newsList/>
    </div>
        <footerComponent />

  </div>
</template>
<script>
import axios from "axios";
import newsList from "@/components/newsList.vue";

export default {
  name: "news",
  data() {
    return {
      endpoint: "https://kols.futurehost.net.pl/wp-json/contact-form-7/v1/contact-forms/11/feedback",
      logoFooter: null,
      response: null,
    };
  },
  components:{newsList},
  mounted(){
    this.getLogoFooter()
  },
  methods: {
    getLogoFooter() {
      const response = axios
        .get("https://kols.futurehost.net.pl/wp-json/kols/v2/settings/logo_news")
        .then((response) => {
          this.logoFooter = response.data.replace("http://","https://");
        })
        .catch((error) => {
          console.log("e", error);
        });
    },
    previousPath() {
      return window.history.back();
    }

  },
};
</script>

<style scoped>
.header {
  background: #1b0260;
  padding: 10px 0;
}
.row {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
}
h1 {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  padding: 0;
}
</style>
<template>
  <div>
        <transition name="fade">

    <div
      v-if="deferredPrompt"
      ref="addBtn"
      class="prompt__ios"
      @click="clickCallback"
    >
      <p>Zainstaluj aplikację na swoim pulpicie. Tapnij <img src="@/assets/images/share.png" alt=""> i "<span>Dodaj do ekranu głownego</span>".</p>
       <button class="prompt__close">zamknij</button>
    </div>
        </transition>
  </div>

</template>

<script>
export default {
  name: "AddToHomeScreen",
  data: () => ({
    deferredPrompt: false,
  }),
  mounted() {
    this.captureEvent();
   this.closePrompt();

  },

  methods: {
    captureEvent() {
      // Detects if device is on iOS
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
      };
      // Detects if device is in standalone mode
      const isInStandaloneMode = () =>
        "standalone" in window.navigator && window.navigator.standalone;

      // Checks if should display install popup notification:
      if (isIos() && !isInStandaloneMode()) {
        this.deferredPrompt= true;
      }

    },
    closePrompt(){
     // console.log('closeprompt');
      window.setTimeout(()=>{

      const closeButton= document.querySelector('.prompt__close');
     // console.log('cb',closeButton);
      if(closeButton != null){
        closeButton.addEventListener("touchstart", ()=>{
          this.deferredPrompt=false;
          console.log('closePrompt');
        })
      }
     },3000)

    }
  },
};
</script>
<style scoped>
.prompt__ios{
  position: fixed;
  bottom: 15px;
  left: 0;
  right: 0;
  z-index: 10;
height: 100px; 
background: #e5e5e5;
border-radius: 5px;
}
.prompt__ios img{
  margin: 0 3px;
  height: auto;
  width: auto;
}
.prompt__ios:after{
  position: absolute;
  content: "";
  left: 50%;
  bottom: -12px;
  transform: translateX(-50%);
  background: url('~@/assets/images/triangleDown.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 12px;
  z-index: 2;
}
.prompt__ios:before{
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -20px;
  height: 20px;
  background: #fff;
  z-index: 1;
}
.prompt__ios span{
  font-weight: 700;
}
.prompt__close{
  position: absolute;
  bottom: 5px;
  right: 5px;
  border: none;
  background: none;
  width: 100px;
  height: 20px;
  text-align: right;
  color: #FF7A07;
  cursor: pointer;
}
.prompt__close img{
  padding: 5px;
  width: 25px;
  height: 25px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<template>
  <div class="container">
    <div class="header">
      <div class="row">
        <a class="header__arrow" v-on:click="previousPath">
          <img src="@/assets/images/arrowLeft.png" alt="strona główna" />
        </a>
      </div>
      <div class="row">
        <h1>{{ title }}</h1>
      </div>
    </div>
        <div class="content" v-html="content"></div>

    <footerComponent />
  </div>
</template>
<script>
// @ is an alias to /src
import footerComponent from "@/components/footerComponent.vue";
import axios from "axios";
export default {
  name: "service",
  components: {
    footerComponent,
  },
  data() {
    return {
      posts: null,
      icon: null,
      title: null,
      content: null,
      phone: null,
      email: null,
      isInfoActive: true,
      isVideoActive: false,
      featuredImage: null,
      endpoint:
        "https://kols.futurehost.net.pl/wp-json/wp/v2/posts/" + this.$route.params.id+"?_embed",
    };
  },
  async mounted() {
    this.getPosts();
  },
  methods: {
    previousPath() {
     return window.history.back();
    },
   async getPosts() {
      axios
        .get(this.endpoint)
        .then((response) => {
          this.featuredImage= response.data._embedded['wp:featuredmedia']['0'].source_url;
          this.posts = response.data;
          this.title = response.data.title.rendered;
          this.content = response.data.content.rendered;

        })
        .catch((error) => {
          console.log("error ! -> ", error);
        });
    },
  },
};
</script>
<style scoped>
.header {
  background: #1b0260;
  padding: 10px 0;
}
.row {
  width: 90%;
  margin: 0 auto;
    display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
}
h1 {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  padding: 0 ;
  text-align: left;
}
.container {
}
.content{
  margin: 10px;
    min-height: calc(100vh - 160px);
}
:deep(.content) p{
  text-align: left;
}
:deep(.content) ul{
  text-align: left;
}
:deep(.content) .wp-block-embed{
  margin: 10px;
  }
 :deep( .content) .wp-block-embed__wrapper{
    margin: 0;
    text-align: left;
  }
:deep(.content) iframe{
  max-width: 98%;
}
:deep(.content) p {
  text-align: left;
}
:deep(.content ) ul {
  text-align: left;
  padding-left: 20px;
}
:deep(.content) ul li {
  list-style-type: none;
  position: relative;
  margin: 10px 0;
}
:deep(.content ) ul li:after {
  position: absolute;
  left: -20px;
  top: 2px;
  content: "";
  width: 15px;
  height: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("~@/assets/images/tick.png");
}
:deep(.content) .alert {
  position: relative;
  background: #e5e5e5;
  border-radius: 10px;
  padding: 10px 10px;
  text-align: left;
  margin-bottom: 20px;
}
:deep(.content ) .alert__triangle {
  position: absolute;
  bottom: -3px;
  left: -9px;
  width: 15px;
  height: 15px;
  background-image: url("`~@/assets/images/triangle.png`");
}
:deep(.content) .alert p {
  padding: 0px 0;
  margin: 0;
  line-height: 1.45rem;
}
</style>
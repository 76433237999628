<template>
  <div >
    <!-- <div class="loader" v-if="!isLoaded"></div> -->
    <router-link class="logo" to="/">
    <img class="logo__image" v-if="logo" v-bind:src="logo" alt="logo" />
    </router-link>
    <div class="container">
      <servicesList />
      <checkNewsComponent v-if="logoFooter" v-bind:logoFooter="logoFooter" />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import servicesList from "@/components/servicesList.vue";
import checkNewsComponent from "@/components/checkNewsComponent.vue";
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      logo: null,
      logoFooter: null,
      isLoaded:false
      //prevRoute:null
    };
  },
  created() {
    this.getLogoFooter(), 
    this.getLogo(),
    this.hideLoader()
  },
  updated(){
  // this.isLoaded=true
  },
  methods: {
    hideLoader(){
      window.addEventListener('load', ()=>{
        this.isLoaded=true
      })
    },
    getLogoFooter() {
      const response = axios
        .get(
          "https://kols.futurehost.net.pl/wp-json/kols/v2/settings/logo_footer"
        )
        .then((response) => {
          this.logoFooter = response.data.replace("http://", 'https://');
        })
        .catch((error) => {
          console.log("e", error);
        });
    },
    getLogo() {
      const response = axios
        .get("https://kols.futurehost.net.pl/wp-json/kols/v2/settings/logo")
        .then((response) => {
          this.logo = response.data.replace("http://", 'https://');
        })
        .catch((error) => {
          console.log("e", error);
        });
    },
  },
  components: {
    servicesList,
    checkNewsComponent,
  },
};
</script>
<style scoped>
.title{
  background: #fff;
  /* text-transform: uppercase; */
  font-size: .9rem;
  text-align: center;
  padding: 20px;
  margin: 0 0 20px 0;
  color: #FF7A07;
}
.loader{
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 9;
background: #ddd;
}
.logo {
  height: 90px;
  display: inline-block;
  position: relative;
    margin: 20px auto;

}

.logo__image {
  height: 100%;
  width: auto;
}

.home {
  /* max-width: 1040px; */
  margin: 0 auto;
  z-index: 1;
  position: relative;
}
.container {
  margin: 0 auto;
  width: 100%;
  min-height: calc(100vh - 300px);
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
}
</style>
<template>
<div  :class="isloaded? 'active services':'services'">
  <router-link
    v-for="post in posts"
    v-bind:key="post"
    v-bind:class="'service__item'"
    :to="{ name: 'service', params: { id: post.id } }"
  >
   <span> <img
      v-if="post.acf.ikonka"
      v-bind:src="post.acf.ikonka"
    /></span>
    <h2 v-html="post.title.rendered"></h2>
    </router-link
  >
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "servicesList",

  data() {
    return {
      posts: null,
      media: null,
      isloaded:false,
      endpoint: "https://kols.futurehost.net.pl/wp-json/wp/v2/uslugi?per_page=100&orderby=menu_order&order=asc",
      mediaEndpoint: "https://kols.futurehost.net.pl/wp-json/wp/v2/media",
    };
  },
  created() {
    this.getAllPosts();
  },
  methods: {
   async getAllPosts() {
     await axios
        .get(this.endpoint)
        .then((response) => {
          this.posts = response.data;
       //  console.log(response.data);
        this.posts.forEach((post, index)=>{
        this.posts[index].title.rendered= post.title.rendered.replace("|","</br>")
        })
          this.getMedia();
        })
        .catch((error) => {
          console.log("error ! -> ", error);
        });
    },
    //pola acf w REST przechowują tylko ID. Dodajemy obiekt media do this.posts
   async getMedia() {
     // console.log(this.posts.length);
      for (let i = 0; i < this.posts.length; i++) {
       await axios
          .get(this.mediaEndpoint + "/" + this.posts[i].acf.ikonka)
          .then((response) => {
//console.log(response.data.guid.rendered);
            this.posts[i].acf.ikonka = response.data.guid.rendered.replace("http://", "https://");
            if (i== this.posts.length-1){
              this.isloaded=true;
            }
          })
          .catch((error) => {
            console.log("media error", error);
          });
      }
      this.posts.forEach((post) => {});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.services{
    margin: 0;
  width:100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  transition: all .7s ease-in-out;
}
.services.active{
  opacity: 1;
}
.service__item {
  position: relative;
  text-align: center;
  margin: 0 auto;
  display: flex;
  justify-content: center;
 flex-direction: column;
 align-items: center;
    text-decoration: none;
    margin: 0vw;
    width: 33.33%;

}
.service__item img{
  width: auto;
  max-width: 30px;
  max-height: 30px;
  height: auto;
}
h2{
  font-size: .9rem;
  line-height: 1.5rem;
  color: #1b0260;
  font-weight: 600;
}
@media all and (max-width:540px){
  h2{font-size: .9rem}
}
.service__item span{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  width: 30px;
  height: 30px;
  
 background: hsl(256, 96%, 19%);
background: linear-gradient(180deg, rgba(27,2,96,0.5830707282913166) 0%, rgba(27,2,96,1) 100%);
}
</style>

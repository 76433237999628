<template>
  <div>
        <div v-if="serviceDescAlert" class="alert">
   <div v-html="serviceDescAlert"></div>
    <div class="alert__triangle">
      <img src="@/assets/images/triangle.png" alt="">
    </div>
    </div>
    <div class="contacts">
      <div class="contacts__row">
        <a class="contacts__single" v-bind:href="`tel:${serviceDescPhone}`">
          <img
            class="contacts__image"
            src="@/assets/images/phone.png"
            alt="Telefon"
          />
          Zadzwoń
        </a>
        <router-link class="contacts__single" :to="'/callrequest'">
          <img
            class="contacts__image"
            src="@/assets/images/list.png"
            alt="Telefon"
          />
          Zamów rozmowę
        </router-link>
      </div>
      <div class="contacts__row">
        <router-link class="contacts__single" :to="'/sendForm'">
          <img
            class="contacts__image"
            src="@/assets/images/envelope.png"
            alt="Telefon"
          />
          Napisz do nas
        </router-link>
        <a
          class="contacts__single"
          v-bind:href="`https://www.facebook.com/sharer/sharer.php?u=https://app.kols.me/`"
          target="_blank"
        >
          <img
            class="contacts__image"
            src="@/assets/images/facebook.png"
            alt="Telefon"
          />
          Udostępnij
        </a>
      </div>
    </div>
    <div class="description__content" v-html="serviceDescContent"></div>

    <div class="featured">
      <img class="featured__logo" src="@/assets/images/logo.svg" alt="">
      <img class="featured__image" v-bind:src="serviceDescFeatured" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "serviceDesc",
  props: {
    serviceDescContent: String,
    serviceDescAlert: String,
    serviceDescPhone: String,
    serviceDescEmail: String,
    serviceDescFeatured: String,
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    currentRouteHref() {
      return this.$route.href;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
  text-align: left;
}
a {
  color: #42b983;
}
.contacts {
  margin: 40px auto;
  max-width: 90%;
  widows: 400px;
}
.contacts__row {
  width: 100%;
  display: flex;
  flex-basis: 50%;
}
.contacts__row:first-child {
  border-bottom: 1px solid #dfdfdf;
}
.contacts__single {
  padding: 10px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: #1b0260;
  font-size: 0.9rem;
}
.contacts__image {
  margin: 10px;
}
.contacts__single:first-child {
  border-right: 1px solid #dfdfdf;
}
.description__content {
  /* width: 100%; */
  position: relative;
  padding: 0 10px;
  color: #1b0260;
    font-size: .9rem;

}
:deep(.description__content) p {
  text-align: left;
}
:deep(.description__content) ul {
  text-align: left;
  padding-left: 20px;
}
:deep(.description__content) ul li {
  list-style-type: none;
  position: relative;
  margin: 10px 0;
}
:deep(.description__content) ul li:after {
  position: absolute;
  left: -20px;
  top: 2px;
  content: "";
  width: 15px;
  height: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("~@/assets/images/tick.png");
}
:deep(.description__content) li {
  text-align: left;
}

.alert {
  position: relative;
  
  background: #e5e5e5;
  border-radius: 10px;
  padding: 10px 10px;
  text-align: left;
  margin: 5px auto 20px auto;
  color: #000;
  white-space: break-spaces;
  
}
.alert__triangle {
  position: absolute;
  bottom: -3px;
  left: -9px;
  width: 15px;
  height: 15px;
  /* background-image: url("`~@/assets/images/triangle.png`"); */
}
.alert p {
  padding: 0px 0;
  margin: 0;
  line-height: 1.45rem;
}
.featured{
  width: 96%;
max-width: 400px;
  margin: 0 auto;
  position: relative;
  display: inline-block;
}
.featured__image{
  width: 100%;
  height: auto;
  border-radius: 20px;
}
.featured__logo{
  position: absolute;
  width: 50%;
  height: auto;
  top:25%;
left: 50%;
transform: translateX(-50%);
z-index: 1;
}
ol{
  display: none;
}
:deep(h2){
  color: #ff7a07;
  text-align: left;
}
:deep(h3){
  color: #1b0260;
  text-align: left;
}
</style>

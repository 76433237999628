<template>
  <div class="container">
    <div class="header">
      <div class="row">
        <a class="header__arrow" v-on:click="previousPath">
          <img src="@/assets/images/arrowLeft.png" alt="strona główna" />
        </a>
      </div>
      <div class="row">
        <img src="@/assets/images/list.png" class="header__icon" />
        <h1>Zostaw dane, oddzwonimy do Ciebie!</h1>
      </div>
    </div>

    <form class="callmeform" v-on:submit.prevent="submitForm">
      <label class="callmeform__text-label"
        ><p class="label__text">Twoje imię i nazwisko</p>
        <input
          v-on:focus="activeInput($event)"
          v-on:blur="inactiveInput($event)"
          class="callmeform__text"
          type="text"
          v-model="form.name"
      /></label>
      <label class="callmeform__text-label"
        ><p class="label__text">Twój telefon</p>
        <input
          v-on:focus="activeInput($event)"
          v-on:blur="inactiveInput($event)"
          class="callmeform__text"
          type="text"
          v-model="form.phone"
      /></label>
        <label class="consent"><input type="checkbox" v-on:click="submitConsent"  id="consent">
      Administratorem Pana/i danych jest Zastępczak24 sp. z o.o. Zapoznałem się z 
      <router-link :to="'/page/3'" >polityką prywatności </router-link> 
      i       
      <router-link :to="'/page/268'" >regulaminem </router-link> .
      </label>
      <vue-recaptcha
        v-if="consent"
        @verify="onVerify"
        sitekey="6LdWQ0AUAAAAAJQt6f92HYKj1wVafakyaPRzkU6u"
      >
      </vue-recaptcha>
      <div v-if="recaptcha && consent" class="callmeform__submit" @click="submitForm">
        Proszę o kontakt
      </div>
    </form>
    <div v-if="response" class="response">
      <p v-if="response">{{ response.data.message }}</p>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "callrequest",
    components: { VueRecaptcha },

  data() {
    return {
      endpoint:
        "https://kols.futurehost.net.pl/wp-json/contact-form-7/v1/contact-forms/11/feedback",
      form: {
        name: null,
        phone: null,
      },
      response: null,
      recaptcha: null,
      consent:false,

    };
  },
  methods: {
    onVerify: function (response) {
      // console.log("Verify: " + response);
      this.recaptcha = response;
    },
    previousPath() {
      return window.history.back();
    },
    activeInput(e) {
      e.target.parentNode.classList.add("active");
    },
    inactiveInput(e) {
      const content = e.target.value;
      if (!content.length > 0) {
        e.target.parentNode.classList.remove("active");
      }
    },
        submitConsent(){
this.consent = !this.consent;
    },
    submitForm() {
      // console.log(this.form);
      const emailBody = {
        "your-name": this.form.name,
        "text-529": this.form.phone,
      };
      const form = new FormData();
      for (const field in emailBody) {
        form.append(field, emailBody[field]);
      }
      // const response = await this.$http.post(this.endpoint, form);

      axios
        .post(this.endpoint, form)
        .then((response) => {
          console.log(response);
          this.response = response;
        })
        .catch((error) => {
          console.log("form error", error);
        });
    },
  },
};
</script>

<style scoped>
.header {
  background: #1b0260;
  padding: 10px 0;
}
.row {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
}
h1 {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  padding: 0 20px;
  text-align: left;
}

.callmeform {
  width: 95%;
  max-width: 400px;
  margin: 50px auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.callmeform__text-label {
  width: 98%;
  /* padding: 12px; */
  margin: 5px 1%;
  border: 1px solid #e5e5e5;
  color: #1b0260;
  position: relative;
  display: flex;
}
.callmeform__text {
  width: 100%;
  padding: 15px 5px;
  border: none;
}
.callmeform__submit {
  padding: 10px 20px;
  min-width: 100px;
  margin: 5px 0 5px auto;
  border: 1px solid #c3f73a;
  color: #1b0260;
  border-radius: 20px;
  font-weight: 700;
}
.callmeform__text-label {
  position: relative;
}
.callmeform__text-label .label__text {
  position: absolute;
  top: 0px;
  left: 10px;
  color: #1b0260;
  z-index: 1;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}
.callmeform__text-label.active .label__text {
  font-size: 0.5rem;
}
.response {
  background: #c3f73a;
  padding: 10px;
  margin: 10px 1%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.response p {
  color: #1b0260;
  font-weight: 700;
  font-size: 0.8rem;
}
.consent{
  text-align: left;
  margin: 5px 0;
}
</style>